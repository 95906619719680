import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import getKitchensQuery from '../queries/get-kitchens.js';

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { sampleSale } from '../../../constants';
const { kitchenId: sampleSaleId } = sampleSale;
const useStyles = makeStyles((theme) => ({
  kitchenSelect: {
    width: '100%'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));

const KitchenSelect = (props) => {
  const {
    label = 'Select a Kitchen',
    margin = 'normal',
    variant = 'outlined',
    path
  } = props;

  const classes = useStyles();
  const { loading, data } = useQuery(getKitchensQuery);

  if (loading || !data) {
    return (
      <Box className={classes.progress}>
        <CircularProgress size='2em' />
      </Box>
    );
  }

  /* Note - Sample Sale location (Morse-Road) only applies to Sample Sale event
and should not be an option for breakfast or lunch pickup */
  const renderKitchenItem = (args) => {
    const { id, name } = args;
    if (path !== '/kitchens' && id === sampleSaleId) {
      return null;
    }
    return (
      <MenuItem key={`kitchen-${id}`} value={id}>
        {name}
      </MenuItem>
    );
  };

  const renderKitchens = (kitchens) => {
    const results = [];
    kitchens.forEach((kitchen) => {
      results.push(renderKitchenItem(kitchen));
    });

    return results;
  };

  const { getKitchens: kitchens } = data;

  const menuItems = renderKitchens(kitchens);
  const { selectedKitchen } = props;
  const _value = selectedKitchen ? selectedKitchen : '';

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;

    props.onChange(value);
  };

  return (
    <FormControl
      variant={variant}
      margin={margin}
      className={classes.kitchenSelect}
    >
      <InputLabel id='kitchen-select-label'>{label}</InputLabel>
      <Select
        labelId='kitchen-select-label'
        id='kitchen-select'
        value={_value}
        onChange={handleChange}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default KitchenSelect;
