import gql from 'graphql-tag';

export default gql`
  query getOrdersQuery($input: FilterInput!) {
    getOrders(input: $input) {
      id
      orderNumber
      kitchenId
      firstName
      lastName
      pickupTime
      ready
      items {
        name
        sides {
          id
          name
        }
        options
      }
    }
  }
`;
