/*
makes the time format that's saved in Dynamo suitable for display in UI
'yyyy-MM-dd' becomes 'MM/dd/yyyy'
*/

const formatPickupDate = (dateInput) => {
  const dateArray = dateInput.split('-');
  const year = dateArray[0] || '';
  const month = dateArray[1] || '';
  const date = dateArray[2] || '';
  return `${month}/${date}/${year}`;
};

export default formatPickupDate;
