import React, { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';
import {
  Paper,
  TextField,
  Button,
  Box,
  FormHelperText,
  makeStyles
} from '@material-ui/core';

import cleanTypeName from '../../../utils/cleanTypeName.js';
import createOrderMutation from '../mutations/create-order.js';
import validatePromoCodeQuery from '../../kitchen/queries/validate-promo-code';

import OrderSummary from './order-summary.js';
import IFrame from './iframe.js';

const useStyles = makeStyles((theme) => ({
  firstInput: {
    marginRight: '1em'
  }
}));

const PaymentScreen = (props) => {
  const classes = useStyles();
  const [callCreateOrder] = useMutation(createOrderMutation);
  const [
    validatePromoCode,
    { data: promoCodeData, loading: promoCodeLoading }
  ] = useLazyQuery(validatePromoCodeQuery, {
    onCompleted: () => {
      handleApplyPromoCode(promoCodeData);
    }
  });
  const [promoCodeInput, setPromoCodeInput] = useState('');
  const { orderDetails } = props;
  const { firstName, lastName, kitchenId, associateId } = orderDetails;
  const isSampleSale = orderDetails.kitchenId === 'MORSE-ROAD';
  const [promoCodeError, setPromoCodeError] = useState(false);
  if (Object.keys(orderDetails).length === 0) {
    props.history.push('/');
  }

  const { items } = orderDetails;
  const total =
    Array.isArray(items) && items.length
      ? items.reduce(
          (accumulator, currentItem) => accumulator + currentItem.price,
          0
        )
      : 0;

  const onSuccess = async (data) => {
    /* associateId should not be stored in the database
    for security reasons */
    const { associateId, ...rest } = orderDetails;
    const payload = cleanTypeName({
      ...rest,
      ...data,
      promoCode: promoCodeInput
    });
    try {
      const result = await callCreateOrder({ variables: { order: payload } });
      const { data: _result } = result;

      if (!_result.createOrder) {
        // eslint-disable-next-line no-console
        console.warn('problem creating order');
        props.history.push('/order-error');
      }

      const { createOrder } = _result;
      const newOrder = createOrder.pop();

      props.history.push({
        pathname: `/confirmation/${newOrder.id}`,
        state: newOrder
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err.message);
      Sentry.captureException(err);
      props.history.push('/order-error');
    }
  };

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    setPromoCodeInput(value);
  };

  const handleApplyPromoCode = (data) => {
    if (!data || !data.validatePromoCode) {
      setPromoCodeError('Not a valid promo code');
      return null;
    }
    if (data && data.validatePromoCode && data.validatePromoCode === true) {
      const payload = {
        amount: 0
      };
      // TODO need some kind of loading indicator here because there's a delay
      onSuccess(payload);
    } else {
      setPromoCodeError('Not a valid promo code');
      return null;
    }
  };

  return (
    <Paper elevation={0}>
      {items ? <OrderSummary items={items} total={total} {...props} /> : null}
      {!isSampleSale ? (
        <Box display='flex' flexDirection='column' marginTop={1.5}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <TextField
              label='Promo Code'
              variant='outlined'
              name='promoCode'
              value={promoCodeInput}
              onChange={handleChange}
              size='small'
              className={classes.firstInput}
            />
            <Button
              color='primary'
              variant='contained'
              disabled={promoCodeLoading}
              onClick={() => {
                validatePromoCode({ variables: { input: promoCodeInput } });
              }}
            >
              {promoCodeLoading ? 'Processing' : 'Apply'}
            </Button>
          </Box>
          {promoCodeError && (
            <FormHelperText error={true}>Not a valid promo code.</FormHelperText>
          )}
        </Box>
      ): null }
      <IFrame
        onSuccess={onSuccess}
        amount={total}
        firstName={firstName}
        lastName={lastName}
        kitchenId={kitchenId}
        associateId={associateId}
        items={items}
      />
    </Paper>
  );
};

export default PaymentScreen;
