import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  Container,
  Typography,
  Button,
  Box,
  Breadcrumbs,
  Paper,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';
import ItemTable from 'mui-datatables';

import eightySixMenuItemMutation from '../mutations/eighty-six-menu-item.js';
import getKitchenQuery from '../queries/get-kitchen';
import { featureFlags } from '../../../constants';
import menuTableColumns from '../components/menu-table-columns';

const tableOptions = {
  selectableRows: 'none',
  elevation: 0,
  print: false,
  viewColumns: false,
  search: false,
  download: false,
  filter: false,
  responsive: 'standard'
};

const useStyles = makeStyles((theme) => ({
  manageMenu: {
    width: '100%',
    marginTop: '2em',
    '& .breadcrumbs': {
      marginBottom: '2em'
    },
    '& .not-eighty-sixed': {
      fill: theme.palette.disable.main
    },
    '& .eighty-sixed': {
      fill: theme.palette.error.main
    },
    '& .not-eighty-sixed:hover': {
      fill: theme.palette.error.main,
      cursor: 'pointer'
    },
    '& .eighty-sixed:hover': {
      fill: theme.palette.disable.main,
      cursor: 'pointer'
    }
  }
}));

const ManageMenu = (props) => {
  const classes = useStyles();
  const { disableAddMenuItem } = featureFlags;
  const { match } = props;
  const { params } = match;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarError] = useState('Problem with Request');
  const { loading, data } = useQuery(getKitchenQuery, {
    variables: {
      input: { kitchenId: params.kitchenId, includeEightySixed: true, includeMaxedOut: true }
    },
    fetchPolicy: 'cache-and-network'
  });
  const [eigthySixMenuItem, { loading: mutationLoading }] = useMutation(
    eightySixMenuItemMutation
  );

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const eightySixItem = async (id, currentValue) => {
    const payload = {
      input: {
        kitchenId: params.kitchenId,
        menuItemId: id,
        eightySixed: currentValue === false ? true : false
      }
    };
    try {
      await eigthySixMenuItem({
        variables: payload
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err.message);
      setShowSnackbar(true);
    }
  };

  const getRow = (menuItem) => {
    const {
      id,
      name,
      menuType,
      itemType,
      price,
      eightySixed,
      maxOrders,
      numberOfSides
    } = menuItem;
    const _price = `$${price.toFixed(2)}`;
    const _type = itemType.toLowerCase();
    const menu = menuType.toLowerCase();
    const _eightySixed = eightySixed ? eightySixed : false;
    const _maxOrders = maxOrders && maxOrders > 0 ? maxOrders : '';
    const includesSide = numberOfSides === 1 ? <CheckIcon /> : '';

    const renderEditButton = () => {
      return (
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          onClick={() => {
            props.history.push(
              `/kitchen/${params.kitchenId}/menu/edit-menu-item/${id}`
            );
          }}
        >
          Edit
        </Button>
      );
    };
    const render86Button = () => {
      const className =
        _eightySixed === true ? 'eighty-sixed' : 'not-eighty-sixed';

      return (
        <CancelIcon
          className={className}
          onClick={() => eightySixItem(id, _eightySixed)}
        />
      );
    };
    const editButton = renderEditButton();
    const eightySixButton = render86Button();

    return [
      id,
      name,
      menu,
      _type,
      _maxOrders,
      _price,
      includesSide,
      editButton,
      eightySixButton
    ];
  };

  if (loading || mutationLoading) return <CircularProgress size='3em' />;
  if (data) {
    const { getKitchen } = data;
    const kitchen = getKitchen[0];
    const { menuItems } = kitchen;
    const rows = menuItems.map((item) => {
      return getRow(item);
    });

    return (
      <Container className={classes.manageMenu}>
        <Paper elevation={0}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/kitchens'>kitchens</Link>
            <Link to={`/kitchen/${params.kitchenId}/orders`}>
              {params.kitchenId} Orders
            </Link>
            <Typography color='textPrimary'>Manage Menu</Typography>
          </Breadcrumbs>
        </Paper>
        <Box display='flex' justifyContent='space-between' mb={3} mt={3}>
          <Typography variant='h6' component='h1'>
            {params.kitchenId} Menu Items
          </Typography>
          {disableAddMenuItem === false ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                props.history.push(
                  `/kitchen/${params.kitchenId}/menu/create-menu-item`
                );
              }}
            >
              Add Menu Item
            </Button>
          ) : null}
        </Box>
        <ItemTable
          options={tableOptions}
          columns={menuTableColumns}
          data={rows}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            variant='filled'
            onClose={handleCloseSnackbar}
            severity='error'
          >
            {snackbarError}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
  return null;
};

export default ManageMenu;
