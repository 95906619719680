import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import getKitchenQuery from '../queries/get-kitchen.js';
import formatTime from '../../../utils/format-time';

const renderOption = (value, label) => {
  return (
    <MenuItem key={`filter-${value}`} value={value}>
      {label}
    </MenuItem>
  );
};

const renderTimeOptions = (pickupTimes) => {
  return pickupTimes.map((pickupTime) => {
    const { time } = pickupTime;
    const label = formatTime(time);
    return renderOption(time, label);
  });
};
const renderItemOptions = (items) => {
  return items.map((item) => {
    const { id, name } = item;
    return renderOption(id, name);
  });
};

const FilterSelect = (props) => {
  const {
    selectedItem,
    selectedTime,
    kitchenId,
    handleSelectTime,
    handleSelectItem
  } = props;
  const { data, loading } = useQuery(getKitchenQuery, {
    variables: {
      input: { kitchenId }
    }
  });
  if (loading) {
    return <p>Loading</p>;
  }
  if (data) {
    const { getKitchen } = data;
    const kitchen = getKitchen[0];
    const { serviceTimes, menuItems } = kitchen;
    const timeOptions = renderTimeOptions(serviceTimes);
    const itemOptions = renderItemOptions(menuItems);

    return (
      <Fragment>
        <Box
          display='flex'
          flexWrap='wrap'
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          maxWidth='500px'
        >
          <Box display='flex' alignItems='center' mr='1.5em'>
            <Typography id={`time-filter-select-label}`} variant='body2'>
              Filter by Pickup Time
            </Typography>
          </Box>
          <Box maxWidth='300px' flex='1 0 25%'>
            <FormControl variant='outlined' fullWidth={true} margin='dense'>
              <Select
                labelId={`time-filter-select-label}`}
                id='time-select'
                value={selectedTime}
                onChange={handleSelectTime}
                displayEmpty
              >
                <MenuItem value=''>None</MenuItem>
                {timeOptions}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          display='flex'
          flexWrap='wrap'
          width='100%'
          maxWidth='500px'
          justifyContent='space-between'
        >
          <Box display='flex' alignItems='center' mr='1.5em'>
            <Typography id={`time-filter-select-label}`} variant='body2'>
              Filter by Item
            </Typography>
          </Box>
          <Box maxWidth='300px' flex='1 0 25%'>
            <FormControl variant='outlined' fullWidth={true} margin='dense'>
              <Select
                labelId={`item-filter-select-label}`}
                id='item-select'
                value={selectedItem}
                onChange={handleSelectItem}
                displayEmpty
              >
                <MenuItem value=''>None</MenuItem>
                {itemOptions}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Fragment>
    );
  }
  return null;
};

export default FilterSelect;
