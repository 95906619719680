import React, { useState, Fragment } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import { Formik } from 'formik';
import {
  CircularProgress,
  Button,
  TextField,
  Paper,
  Typography,
  FormHelperText,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

import getKitchensQuery from '../../kitchen/queries/get-kitchens';
import getKitchenQuery from '../../kitchen/queries/get-kitchen';
import validationSchema from './validation-schema';
import getPickupDate from '../../../utils/get-pickup-date';
import isMenuAvailable from '../../../utils/is-menu-available';

import KitchenSelect from '../../kitchen/components/kitchen-select';
import TimeSelect from './time-select';
import MenuForm from './lunch-menu-form';
import { sides } from '../../../constants';
import { desiredDateFormats } from '../../../constants/index';
const { dynamoFormat } = desiredDateFormats;

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    width: '100%'
  },
  submitButton: {
    marginTop: '2em'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  },
  ordersClosedTitle: {
    marginBottom: '1.5em'
  },
  smsDisclaimer: {
    marginTop: '1em'
  }
}));

/* TODO make this screen and/or menu form modular so it can be reused for sample sale and starbucks
There is a lot of duplicate code between this and breakfast */
const LunchMenuScreen = (props) => {
  const { saveOrderDetails } = props;
  const classes = useStyles();
  const [selectedKitchen, setSelectedKitchen] = useState(null);
  const [selectedEntree, setSelectedEntree] = useState('');
  const [selectedSide, setSelectedSide] = useState({
    id: 'side-none',
    name: 'no side'
  });
  const [selectedOption, setSelectedOption] = useState('');
  const [optionsError, setOptionsError] = useState(false);
  const { data, loading } = useQuery(getKitchensQuery);
  const [
    getKitchen,
    { loading: kitchenLoading, data: kitchenData }
  ] = useLazyQuery(getKitchenQuery, { fetchPolicy: 'network-only' });
  const { match } = props;
  const { path } = match;
  const pickupDate = getPickupDate();
  const available = isMenuAvailable('LUNCH');

  const initialFormValues = {
    firstName: '',
    lastName: '',
    pickupTime: '',
    phone: ''
  };

  const resetForm = () => {
    setSelectedEntree('');
    setSelectedSide({
      id: 'side-none',
      name: 'no side'
    });
    setSelectedOption('');
    setOptionsError(false);
  };

  const proceedToPayment = (values) => {
    const side = selectedSide || null;
    const { id, itemType, price, name, options } = selectedEntree;
    // note - a selected option is required for entrees with options
    if (
      options &&
      Array.isArray(options) &&
      options.length > 0 &&
      !selectedOption
    ) {
      setOptionsError(true);
      return null;
    }
    const entreeObject = { id, name, itemType, price };
    const _pickupDate = format(pickupDate, dynamoFormat);
    const option = selectedOption || null;
    const orderObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      pickupTime: values.pickupTime,
      phone: values.phone,
      kitchenId: selectedKitchen,
      orderDate: _pickupDate,
      items: [{ ...entreeObject, sides: [side], options: [option] }]
    };
    saveOrderDetails(orderObject);
    props.history.push('/payment');
  };

  const handleSelectEntree = (event, allMenuItems) => {
    const { target } = event;
    const { value, checked } = target;
    if (checked) {
      setSelectedSide({ id: 'side-none', name: 'no side' });
      const _selectedEntree = allMenuItems.find((item) => item.id === value);
      setSelectedEntree(_selectedEntree);
    }
    if (!checked) {
      resetForm();
    }
  };

  const handleSelectSide = (event) => {
    const { target } = event;
    const { value } = target;
    const _selectedSide = sides.lunch.find((side) => side.id === value);
    setSelectedSide(_selectedSide);
  };

  const handleSelectOption = (event) => {
    const { target } = event;
    const { value } = target;
    setSelectedOption(value);
    setOptionsError(false);
  };
  if (!available && path !== '/bypass-orders-closed/lunch') {
    return (
      <Box marginTop={3} textAlign='center'>
        <Typography
          variant='h3'
          color='error'
          className={classes.ordersClosedTitle}
        >
          Sorry, lunch orders are not being accepted at this time.
        </Typography>
        <Typography variant='body2'>
          Orders are accepted from 2pm day prior to 11am day of order pickup.
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return <CircularProgress size='2em' className={classes.progress} />;
  }

  if (data) {
    const { getKitchens: kitchens } = data;
    return (
      <Paper elevation={0}>
        <Typography color='primary' variant='body2'>
          Please fill out your order details below.
        </Typography>
        <Formik
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialFormValues}
          onSubmit={proceedToPayment}
        >
          {(props) => {
            const {
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors
            } = props;
            const handleSelectKitchen = (kitchen) => {
              const _pickupDate = format(pickupDate, dynamoFormat);
              setSelectedKitchen(kitchen);
              getKitchen({
                variables: {
                  input: { kitchenId: kitchen, orderDate: _pickupDate }
                }
              });
              values.pickupTime = '';
              resetForm();
            };
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  required
                  label='First Name'
                  onChange={handleChange}
                  value={values.firstName}
                  name='firstName'
                  type='text'
                  variant='outlined'
                  margin='dense'
                  className={classes.input}
                />
                <TextField
                  required
                  label='Last Name'
                  onChange={handleChange}
                  value={values.lastName}
                  name='lastName'
                  type='text'
                  variant='outlined'
                  margin='dense'
                  className={classes.input}
                />
                <Typography
                  color='primary'
                  variant='body2'
                  className={classes.smsDisclaimer}
                >
                  You will receive a text message with your order confirmation
                  and receipt.
                </Typography>

                <NumberFormat
                  onValueChange={(val) => setFieldValue('phone', val.value)}
                  value={values.phone}
                  name='phone'
                  type='tel'
                  mask='_'
                  margin='dense'
                  label='Cell Phone Number'
                  required
                  variant='outlined'
                  allowEmptyFormatting
                  format='+1 (###) ###-####'
                  customInput={TextField}
                />
                {errors && errors.phone ? (
                  <FormHelperText error={true}>{errors.phone}</FormHelperText>
                ) : null}
                <KitchenSelect
                  onChange={handleSelectKitchen}
                  label='Select a Pickup Location'
                  selectedKitchen={selectedKitchen}
                  className={classes.input}
                  variant='standard'
                />
                {selectedKitchen && (
                  <TimeSelect
                    handleChange={handleChange}
                    values={values}
                    selectedKitchen={selectedKitchen}
                    kitchens={kitchens}
                    pickupDate={pickupDate}
                    menuType='LUNCH'
                  />
                )}
                {selectedKitchen && kitchenData && values.pickupTime ? (
                  <Fragment>
                    <Typography
                      color='primary'
                      variant='body2'
                      gutterBottom={true}
                    >
                      Please select one entree.
                    </Typography>
                    <MenuForm
                      kitchenData={kitchenData}
                      kitchenLoading={kitchenLoading}
                      selectedKitchen={selectedKitchen}
                      selectedEntree={selectedEntree}
                      handleSelectEntree={handleSelectEntree}
                      selectedSide={selectedSide}
                      handleSelectSide={handleSelectSide}
                      selectedOption={selectedOption}
                      handleSelectOption={handleSelectOption}
                      optionsError={optionsError}
                    />
                  </Fragment>
                ) : null}
                {selectedKitchen &&
                  kitchenData &&
                  values.pickupTime &&
                  selectedEntree ? (
                    <Button
                      className={classes.submitButton}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Proceed to Payment
                    </Button>
                  ) : null}
              </form>
            );
          }}
        </Formik>
      </Paper>
    );
  }
  return null;
};

export default LunchMenuScreen;
