import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { httpOptions, iframeMaxRetries } from '../../../constants/index.js';

const { iframeTimeout } = httpOptions;
/*
  we proxy the iframe request through a lambda to avoid CORS errors.
  the gateway is the same as the GQL endpoint, but there is a HTTP
  handler listening for GET on this iframe-url path.
*/
const { REACT_APP_API_KEY, REACT_APP_GRAPHQL_ENDPOINT } = process.env;
const iframeEndpoint = `${REACT_APP_GRAPHQL_ENDPOINT}/iframe-url`;
const paymentEndpoint = `${REACT_APP_GRAPHQL_ENDPOINT}/payment`;
const headers = {
  'x-api-key': REACT_APP_API_KEY
};

const useStyles = makeStyles((theme) => ({
  paymentCapture: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2em',
    '& .iframe': {
      height: 380,
      width: '100%'
    },
    '& .submitButton': {
      marginTop: '2em'
    }
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));

const aurusSuccessCodes = ['00000', '000'];

const IFrame = (props) => {
  const {
    onSuccess,
    amount,
    firstName,
    lastName,
    kitchenId,
    associateId,
    items
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(1);
  const [sessionData, setSessionData] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [tokenRequested, setTokenRequested] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarError, setSnackbarError] = useState('danger will robinson!');

  const handleAurusErrors = useCallback(() => {
    setSnackbarError('There was a problem with payments. Please try again.');
    setShowSnackbar(true);
    setIsDisabled(false);
    setLoading(false);
  }, []);

  const handleEnablePlaceOrder = useCallback((value) => {
    // inverts to match name
    const _value = value === 'false' ? true : false;
    setIsDisabled(_value);
  }, []);

  const handleOrderCreation = useCallback(
    async (response) => {
      setLoading(true);
      const payload = {
        kitchenId,
        items,
        amount,
        firstName,
        lastName,
        associateId,
        paymentDetails: response,
        sessionData
      };
      // post for capture of the payment
      try {
        const result = await axios.post(paymentEndpoint, payload, { headers });
        const { data } = result;

        if (!aurusSuccessCodes.includes(data.code)) {
          return handleAurusErrors();
        }
        const orderWithMeta = {
          amount,
          meta: {
            token: response,
            session: sessionData,
            payment: data
          }
        };

        return onSuccess(orderWithMeta);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err.message);
        /*
          note: currently we are getting invalid session ip on the
          second load of the iframe for a given browser session. this
          catch will trigger the snackbar, but also show the invalid
          session error. should go away once aurus resolves the issue.
        */
        return handleAurusErrors();
      }
    },
    [
      amount,
      associateId,
      firstName,
      handleAurusErrors,
      items,
      kitchenId,
      lastName,
      onSuccess,
      sessionData
    ]
  );

  // handle the iframe events
  const onMessageReceived = useCallback(
    async (event) => {
      event.preventDefault();
      const { data } = event;
      const [key, value] = typeof data === 'string' ? data.split('=') : [];

      if (key === 'enablePlaceOrder') {
        return handleEnablePlaceOrder(value);
      }

      if (key === 'response') {
        const response = JSON.parse(value);

        if (!aurusSuccessCodes.includes(response.response_code)) {
          return handleAurusErrors();
        }

        await handleOrderCreation(response);
      }
    },
    [handleAurusErrors, handleEnablePlaceOrder, handleOrderCreation]
  );

  // listen to events from the iframe
  useEffect(() => {
    window.addEventListener('message', onMessageReceived, false);

    return () => {
      window.removeEventListener('message', onMessageReceived, false);
    };
  }, [onMessageReceived]);

  useEffect(() => {
    const doWork = async () => {
      try {
        const _iframeEndpoint = kitchenId
          ? `${iframeEndpoint}/${kitchenId}`
          : iframeEndpoint;
        const result = await axios.get(_iframeEndpoint, {
          headers,
          timeout: iframeTimeout
        });
        const { data } = result;

        setSessionData(data);
        setLoading(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err.message);
        return setRetryCount(retryCount + 1);
      }
    };
    /*
      we have some latency with cold starts on the lambda that 
      can cause the loading of the iframe to fail and spin
      forever. this is an attempt to catch the second lambda that
      spins up with a retry until we hit the max.
    */
    if (kitchenId) {
      if (retryCount < iframeMaxRetries) {
        setLoading(true);
        doWork();
      } else {
        setLoading(false);
        setSessionData({ IFrameUrl: '' });
        setSnackbarError('We are experiencing technical difficulties.');
        setShowSnackbar(true);
      }
    }
  }, [kitchenId, retryCount, setRetryCount]);

  if (loading || !sessionData) {
    return <CircularProgress className={classes.progress} size='2em' />;
  }

  const handleSubmit = async () => {
    if (tokenRequested === false) {
      setIsDisabled(true);
      setTokenRequested(true);

      try {
        const frame = document.getElementById('paymentDetails');
        await frame.contentWindow.postMessage('aurus-token', '*');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    setSnackbarError('');
  };

  return (
    <Box className={classes.paymentCapture}>
      <Typography variant='subtitle2' color='primary' gutterBottom>
        Please enter your payment information below.
      </Typography>
      <iframe
        id='paymentDetails'
        title='order-payment'
        className='iframe'
        src={sessionData.IFrameUrl}
      ></iframe>
      <Button
        disabled={isDisabled}
        onClick={handleSubmit}
        variant='contained'
        color='primary'
        className='submitButton'
      >
        Place Order and Pay
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert variant='filled' onClose={handleCloseSnackbar} severity='error'>
          {snackbarError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IFrame;
