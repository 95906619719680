import gql from 'graphql-tag';

export default gql`
  mutation markOrderReadyForPickupMutation($id: String!) {
    markOrderReadyForPickup(id: $id) {
      id
      orderNumber
      kitchenId
      firstName
      lastName
      pickupTime
      ready
    }
  }
`;