import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Box,
  Divider,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import { sides } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  },
  form: {
    '& .accordion-form': {
      paddingTop: 0,
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    '& .accordion-title': {
      marginBottom: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content.Mui-expanded': {
        minHeight: 0,
        margin: 0
      }
    },
    '& .list-item': {
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'capitalize'
    },
    '& .list-item-component': {
      width: '100%'
    }
  }
}));

const getBreakfastMenuItems = (items) => {
  return items.filter((item) => item.menuType === 'BREAKFAST');
};

const BreakfastMenuForm = (props) => {
  const classes = useStyles();
  const {
    kitchenData,
    kitchenLoading,
    handleSelectEntree,
    selectedEntree,
    handleSelectSide,
    selectedSide,
    selectedOption,
    handleSelectOption,
    optionsError
  } = props;

  const renderSidesMenu = () => {
    const options = sides.breakfast.map((side) => {
      return (
        <MenuItem key={side.id} value={side.id}>
          {side.name}
        </MenuItem>
      );
    });
    return (
      <FormControl className='list-item-component'>
        <InputLabel id='side-select'>Choose a Side</InputLabel>
        <Select
          labelId='side-select'
          name='sideItem'
          value={selectedSide.id || ''}
          onChange={handleSelectSide}
        >
          {options}
        </Select>
      </FormControl>
    );
  };

  const renderOptionsMenu = (options) => {
    const selectOptions = options.map((option) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    });

    return (
      <FormControl className='list-item-component'>
        <InputLabel id='option-select'>Choose an Option</InputLabel>
        <Select
          labelId='option-select'
          name='option'
          value={selectedOption || ''}
          onChange={handleSelectOption}
        >
          {selectOptions}
        </Select>
        {optionsError && (
          <FormHelperText error>An option is required</FormHelperText>
        )}
      </FormControl>
    );
  };

  if (kitchenLoading) {
    return <CircularProgress size='2em' className={classes.progress} />;
  }

  if (kitchenData) {
    const { getKitchen } = kitchenData;
    const { menuItems } = getKitchen[0];

    const renderMenuItem = (item) => {
      const { name, id, price } = item;
      const _price = price.toFixed(2);
      const label = (
        <Typography variant='body2'>{`${name} - $${_price}`}</Typography>
      );
      return (
        <ListItem
          key={id}
          dense={true}
          divider={true}
          disableGutters={true}
          className='list-item'
        >
          <FormControl className='list-item-component'>
            <FormControlLabel
              value={id}
              label={label}
              labelPlacement='end'
              onChange={(event) => {
                handleSelectEntree(event, breakfastMenuItems);
              }}
              checked={selectedEntree.id === id}
              control={<Checkbox color='primary' />}
            />
          </FormControl>
          {selectedEntree.id === id &&
          item.options &&
          Array.isArray(item.options) &&
          item.options.length > 0
            ? renderOptionsMenu(item.options)
            : null}
          {selectedEntree.id === id && item.numberOfSides === 1
            ? renderSidesMenu()
            : null}
        </ListItem>
      );
    };

    const getCategoryInputs = (category, allItems) => {
      const categoryItems = allItems.filter(
        (item) => item.itemType === category
      );
      if (categoryItems.length === 0) {
        return null;
      }
      return categoryItems.map((item) => {
        return renderMenuItem(item);
      });
    };

    const breakfastMenuItems = getBreakfastMenuItems(menuItems);
    const generalInputs = getCategoryInputs('NONE', breakfastMenuItems);
    const specialInputs = getCategoryInputs('SPECIAL', breakfastMenuItems);
    return (
      <Paper elevation={0} className={classes.form}>
        {generalInputs && (
          <Accordion variant='outlined' defaultExpanded={true}>
            <AccordionSummary
              className='accordion-title'
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant='subtitle1'>Breakfast</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Box className='accordion-form'>
                <Typography variant='caption'>
                  All include a potato triangle and bottled water.
                </Typography>

                <List disablePadding={true}>{generalInputs}</List>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {specialInputs && (
          <Accordion variant='outlined'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className='accordion-title'
            >
              <Typography variant='subtitle1'>Specials</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.MuiAccordionDetails}>
              <Box className='accordion-form'>
                <Typography variant='caption'>
                  All include a potato triangle and bottled water.
                </Typography>
                <List disablePadding={true}>{specialInputs}</List>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Paper>
    );
  }
  return null;
};

export default BreakfastMenuForm;
