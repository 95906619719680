import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 55,
    '& .name': {
      fontSize: '2em',
      '& .name-link': {
        textDecoration: 'none',
        color: 'inherit'
      }
    },
    '& .secondary-name': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1em'
    }
  }
}));

const Header = (props) => {
  const classes = useStyles();

  return (
    <Container className={classes.header} maxWidth={false}>
      <Box className='name'>
        <Link className='name-link' to='/'>
          Bath &amp; Body Works
        </Link>
      </Box>
      <Container className='secondary-name'>
        <Box>Cafe</Box>
        <Box>Ordering</Box>
      </Container>
    </Container>
  );
};

export default Header;
