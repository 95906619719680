import React, { useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import AuthContext from '../../context/auth-context.js';
import ErrorHandler from '../../error-handler.js';

import AuthenticatedRoute from './components/authenticated-route.js';

import Login from './login.js';
import KitchenSelection from './selection.js';
import KitchenOrders from './orders.js';
import ManageMenu from './menu/manage-menu.js';
import CreateMenuItem from './menu/create-menu-item.js';
import EditMenuItem from './menu/edit-menu-item.js';

const KitchenRoutes = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(AuthContext);

  const loginSuccess = (payload) => {
    dispatch({ type: 'login-success', payload });
    props.history.push('/kitchens');
  };

  const authChallenge = (payload) => {
    dispatch({ type: 'auth-challenge', payload });
    props.history.push('/kitchens');
  };

  const loginHandlers = {
    success: loginSuccess,
    challenge: authChallenge
  };

  return (
    <ErrorHandler>
      <Switch>
        <AuthenticatedRoute
          {...props}
          {...loginHandlers}
          exact
          path='/kitchens'
          component={KitchenSelection}
        />
        <AuthenticatedRoute
          {...loginHandlers}
          exact
          path='/kitchen/:kitchenId/orders'
          component={KitchenOrders}
        />
        <AuthenticatedRoute
          {...loginHandlers}
          exact
          path='/kitchen/:kitchenId/menu'
          component={ManageMenu}
        />
        <AuthenticatedRoute
          {...loginHandlers}
          exact
          path='/kitchen/:kitchenId/menu/create-menu-item'
          component={CreateMenuItem}
        />
        <AuthenticatedRoute
          {...loginHandlers}
          exact
          path='/kitchen/:kitchenId/menu/edit-menu-item/:itemId'
          component={EditMenuItem}
        />
        <Route
          exact
          path='/kitchen'
          render={() => (
            <Login success={loginSuccess} challenge={authChallenge} />
          )}
        />
        <Redirect to='/' />
      </Switch>
    </ErrorHandler>
  );
};

export default withRouter(KitchenRoutes);
