export default [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'menu',
    label: 'Menu',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'maxOrders',
    label: 'Max Orders',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'price',
    label: 'Price',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'includesSide',
    label: 'Includes Side?',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: '',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'eightySixed',
    label: '86',
    options: {
      filter: false,
      sort: false
    }
  }
];
