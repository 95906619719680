export default [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    label: 'Order #',
    name: 'orderNumber',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    label: 'Name',
    name: 'firstName',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    label: 'Pickup Time',
    name: 'pickupTime',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    label: 'Items',
    name: 'items',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    label: 'Sides',
    name: 'sides',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    label: 'Ready',
    name: 'ready',
    options: {
      filter: false,
      sort: false
    }
  }
];
