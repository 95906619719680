import React from 'react';
import { Formik, Field, FieldArray } from 'formik';
import {
  TextField,
  Button,
  Box,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { AddCircle, HighlightOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import validationSchema from './validation-schema';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: '600px',
    marginTop: '.75em',
    '& .full-input': {
      width: '100%'
    },
    '& .first-input': {
      flexBasis: '50%',
      marginRight: '1em'
    },
    '& .second-input': {
      flexBasis: '50%'
    },
    '& .submit-button': {
      marginRight: '5px',
      width: '100px'
    },
    '& .cancel-button': {
      width: '100px'
    },
    '& .add-button, .remove-button': {
      marginLeft: '5px',
      cursor: 'pointer'
    },
    '& .add-button': {
      color: theme.palette.success.main
    },
    '& .remove-button': {
      color: theme.palette.error.main
    }
  },
  label: {
    backgroundColor: 'white'
  },
  checkboxLabel: {
    marginLeft: '10px'
  }
}));

const StyledCheckbox = ({ field, form, props }) => {
  return (
    <FormControlLabel
      label='Include a side?'
      labelPlacement='start'
      control={<Checkbox color='primary' {...field} {...props} />}
    />
  );
};

const StyledInput = ({ field, form, props }) => {
  return <TextField variant='outlined' margin='dense' {...field} {...props} />;
};

const MenuItemForm = (props) => {
  const classes = useStyles();
  const { initialFormValues, handleSave, match, history } = props;
  const { params } = match;
  const startAdornment = <InputAdornment positin='start'>$</InputAdornment>;
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialFormValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {(props) => {
        const { values, handleChange, handleSubmit, disabled, errors } = props;
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' width='100%' mb={'1em'}>
                <TextField
                  required
                  label='Name'
                  onChange={handleChange}
                  value={values.name}
                  name='name'
                  type='text'
                  variant='outlined'
                  margin='dense'
                  className='full-input'
                />
              </Box>
              <Box display='flex' width='100%' mb={'1em'}>
                <FormControl
                  className='first-input'
                  margin='dense'
                  variant='outlined'
                  required
                >
                  <InputLabel id='menu-label' className={classes.label}>
                    Menu
                  </InputLabel>
                  <Select
                    labelId='menu-label'
                    onChange={handleChange}
                    value={values.menu}
                    name='menu'
                  >
                    <MenuItem value='LUNCH'>Lunch</MenuItem>
                    <MenuItem value='BREAKFAST'>Breakfast</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  className='second-input'
                  margin='dense'
                  variant='outlined'
                  required
                >
                  <InputLabel id='type-label' className={classes.label}>
                    Type
                  </InputLabel>
                  <Select
                    labelId='type-label'
                    onChange={handleChange}
                    value={values.type}
                    name='type'
                  >
                    <MenuItem value='NONE'>None</MenuItem>
                    <MenuItem value='SANDWICH'>Sandwich (lunch only)</MenuItem>
                    <MenuItem value='SALAD'>Salad (lunch only)</MenuItem>
                    <MenuItem value='SPECIAL'>Special</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {values.type === 'SPECIAL' && (
                <Box display='flex' width='100%' mb={'1em'}>
                  <TextField
                    label='Max Orders'
                    onChange={handleChange}
                    value={values.maxOrders}
                    name='maxOrders'
                    type='number'
                    variant='outlined'
                    margin='dense'
                    className='first-input'
                  />
                </Box>
              )}
              <Box display='flex' width='100%' mb={'1em'}>
                <Box display='flex' flexDirection='column'>
                  <TextField
                    required
                    label='Price'
                    onChange={handleChange}
                    value={values.price}
                    name='price'
                    type='number'
                    variant='outlined'
                    margin='dense'
                    className='first-input'
                    InputProps={{ startAdornment: startAdornment }}
                  />
                  {errors.price && (
                    <FormHelperText error={true}>{errors.price}</FormHelperText>
                  )}
                </Box>
                <Field
                  type='checkbox'
                  name='includeSide'
                  component={StyledCheckbox}
                />
              </Box>
              <FieldArray name='options'>
                {({ insert, remove, push }) => {
                  return (
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      mb={'1em'}
                    >
                      <Box display='flex'>
                        <Typography>Add an Option</Typography>
                        <AddCircle
                          className='add-button'
                          onClick={() => push('')}
                        />
                      </Box>
                      {values.options.length > 0 &&
                        values.options.map((option, index) => {
                          return (
                            <Box
                              display='flex'
                              key={index}
                              mt={1}
                              alignItems='center'
                            >
                              <Field
                                name={`options.${index}`}
                                type='text'
                                className='first-input'
                                component={StyledInput}
                              />
                              <HighlightOff
                                className='remove-button'
                                key={index}
                                onClick={() => remove(index)}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  );
                }}
              </FieldArray>
              <Box display='flex' justifyContent='flex-end'>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={disabled}
                  className='submit-button'
                >
                  Save
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={disabled}
                  onClick={() => {
                    history.push(`/kitchen/${params.kitchenId}/menu`);
                  }}
                  className='cancel-button'
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default MenuItemForm;
