import gql from 'graphql-tag';

export default gql`
  mutation createOrderMutation($order: NewOrderInput!) {
    createOrder(order: $order) {
      id
      firstName
      lastName
      pickupTime
      kitchenId
      orderDate
      orderNumber
      phone
      email
      items {
        id
        itemType
        price
      }
    }
  }
`;
