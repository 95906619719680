import React, { useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  makeStyles,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const {
  REACT_APP_GRAPHQL_ENDPOINT: endpoint,
  REACT_APP_API_KEY,
  REACT_APP_USER_POOL_CLIENT: clientId
} = process.env;
const signInPath = '/sign-in';
const signInUrl = `${endpoint}${signInPath}`;
const authFlow = 'USER_PASSWORD_AUTH';
const useStyles = makeStyles((theme) => ({
  loginButton: {
    marginTop: '1em'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));
const headers = {
  'x-api-key': REACT_APP_API_KEY
};

const Login = (props) => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarError] = useState('Problem with Login Request');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    const payload = {
      authFlow,
      clientId,
      authParams: {
        user: email,
        password
      }
    };

    try {
      setIsLoading(true);
      setIsDisabled(true);
      const result = await axios.post(signInUrl, payload, { headers });
      const { data } = result;

      if (data.ChallengeName) {
        return props.challenge({ user: email, ...data });
      }

      return props.success({ user: email, ...data });
    } catch (err) {
      Sentry.captureException(err);
      setIsLoading(false);
      setIsDisabled(false);
      setShowSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth='xs'>
      <Box
        marginTop={2}
        display='flex'
        flexDirection='column'
        textAlign='center'
      >
        {isLoading ? (
          <CircularProgress className={classes.progress} size='2em' />
        ) : null}
        <Typography variant='h3' gutterBottom={true}>
          Kitchen Login
        </Typography>
        <TextField
          label='Email'
          name='email'
          variant='outlined'
          margin='dense'
          value={email}
          onChange={handleChange}
        />
        <TextField
          label='Password'
          name='password'
          type='password'
          variant='outlined'
          margin='dense'
          value={password}
          onChange={handleChange}
        />
        <Button
          onClick={handleSignIn}
          color='primary'
          variant='contained'
          className={classes.loginButton}
          disabled={isDisabled}
        >
          Login
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert variant='filled' onClose={handleCloseSnackbar} severity='error'>
          {snackbarError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
