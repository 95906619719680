import gql from 'graphql-tag';

export default gql`
  {
    getKitchens {
      id
      name
      menuItems {
        id
        name
        menuType
        itemType
        price
      }
      serviceTimes {
        time
      }
    }
  }
`;
