import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import formatPickupTime from '../../../utils/format-time';
import formatPickupDate from '../../../utils/format-pickup-date';
import { sampleSale } from '../../../constants';
const { kitchenId: sampleSaleKitchenId } = sampleSale;

const useStyles = makeStyles((theme) => ({
  confirmation: {
    marginTop: '2em',
    textAlign: 'center',
    '& .order-id': {
      margin: '2em'
    }
  }
}));

const renderSampleSaleText = (kitchenId) => {
  if (kitchenId !== sampleSaleKitchenId) {
    return null;
  }
  return (
    <Fragment>
      <Box className='order-id'>
        <Typography>
          Only ONE order per associate is allowed! Please do not place another
          order.
        </Typography>
      </Box>
      <Box className='order-id'>
        <Typography>
          Please SAVE or PRINT this confirmation for your records.
        </Typography>
      </Box>
    </Fragment>
  );
};

const formatTimeForPayment = (pickupTime) => {
  return formatPickupTime(pickupTime);
};

const renderPhone = (phone) => {
  if (!phone)
    return <Typography component='span'>the number provided.</Typography>;

  return (
    <NumberFormat displayType='text' format='+1 ###-###-####' value={phone} />
  );
};

const OrderConfirmation = (props) => {
  const classes = useStyles();
  const { location } = props;

  if (!location || (location && !location.state)) {
    /*
       we have not state, which could be due to a page refresh. but
       is more likely due to a bookmark on an order confirmation
       screen that is not the result of an order. we could load the
       order again, but that could lead to issues with old order
       dates. so get them out of here and back to a new order screen.
    */
    return <Redirect to="/" />;
  }
  // state should be the new order item
  const { state } = location;
  const { kitchenId = '', email = '' } = state;
  const phone = state && state.phone ? renderPhone(state.phone) : '';
  const orderDate = formatPickupDate(state.orderDate);
  const pickupTime = formatTimeForPayment(state.pickupTime);
  const sampleSaleText = renderSampleSaleText(kitchenId);
  const locationText =
    kitchenId === sampleSaleKitchenId
      ? 'L Brands Morse Road Campus'
      : kitchenId;

  return (
    <Box marginBottom={1} className={classes.confirmation}>
      <Typography variant='h2' color='primary'>
        Thank you.
      </Typography>
      <Typography variant='h2' color='primary' gutterBottom>
        Your order has been placed.
      </Typography>
      {sampleSaleText}
      <Box className='order-id'>
        <Typography>Here is your Order Id:</Typography>
        <Typography>#{state.orderNumber}</Typography>
      </Box>
      <Box className='order-id'>
        <Typography>Your order will be ready for pickup on:</Typography>
        <Typography>
          {orderDate}{' '}
          {kitchenId === sampleSaleKitchenId ? 'starting at ' : '@ '}
        </Typography>
        <Typography>
          {pickupTime} at {locationText}.
        </Typography>
      </Box>
      <Box className='order-id'>
        <Typography>
          Confirmation will be sent via text to {phone}{' '}
          {kitchenId === sampleSaleKitchenId
            ? `and ${email}. Additional pickup details will be emailed to you`
            : null}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
