import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    alignItems: 'flex-start'
  }
}));

const OrderSummary = (props) => {
  const classes = useStyles();
  const { items, total } = props;
  const history = useHistory();
  const resetOrder = () => {
    history.goBack();
  };

  const renderListItem = (listItem) => {
    const { name, sides, price, id } = listItem;
    const side = sides && sides[0] && sides[0].name ? `& ${sides[0].name}` : '';
    const _price =
      typeof price === 'number' ? `$${price.toFixed(2)}` : `$${price}`;
    return (
      <ListItem key={id} disableGutters={true} className={classes.listItem}>
        <Box>{`${name} ${side}`}</Box>
        <Box paddingLeft={1}>{_price}</Box>
      </ListItem>
    );
  };

  if (items && items.length > 0) {
    const list = items.map((item) => {
      return renderListItem(item);
    });
    const _total = typeof total === 'number' ? `$${total.toFixed(2)}` : total;
    return (
      <Fragment>
        <Box marginBottom={1}>
          <Typography variant='h6'>Order Summary</Typography>
          <List>
            {list}
            <ListItem disableGutters={true} className={classes.listItem}>
              <Box fontWeight='fontWeightMedium'>Total</Box>
              <Box paddingLeft={1} fontWeight='fontWeightMedium'>
                {_total}
              </Box>
            </ListItem>
          </List>
          <Button
            size='small'
            variant='outlined'
            color='secondary'
            onClick={resetOrder}
          >
            Reset Order
          </Button>
        </Box>
        <Divider className={classes.divider} />
      </Fragment>
    );
  }
  return 'Order is empty';
};

export default OrderSummary;
