import React, { useState } from 'react';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import KitchenSelect from './components/kitchen-select.js';

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: '2em'
  }
}));

const KitchenSelection = (props) => {
  const classes = useStyles();
  const [selectedKitchen, setSelectedKitchen] = useState();
  const { match } = props;
  const { path } = match;

  const onSelectKitchen = (kitchenId) => {
    setSelectedKitchen(kitchenId);
    props.history.push(`/kitchen/${kitchenId}/orders`);
  };

  return (
    <Container maxWidth='sm'>
      <KitchenSelect
        className={classes.select}
        onChange={onSelectKitchen}
        selectedKitchen={selectedKitchen}
        path={path}
      />
    </Container>
  );
};

export default KitchenSelection;
