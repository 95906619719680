import gql from 'graphql-tag';

export default gql`
  mutation editMenuItemMutation($input: CreateMenuItemInput!) {
    editMenuItem(input: $input) {
      id
      name
      menuItems {
        name
        id
        price
        itemType
        eightySixed
      }
    }
  }
`;
