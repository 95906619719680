import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  Container,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import editItemMutation from '../mutations/edit-menu-item';
import getKitchenQuery from '../queries/get-kitchen';

import ItemForm from './form';

const useStyles = makeStyles((theme) => ({
  addItem: {
    width: '100%',
    marginTop: '2em',
    '& .breadcrumbs': {
      marginBottom: '2em'
    }
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));

const EditMenuItem = (props) => {
  const classes = useStyles();
  const { match } = props;
  const { params } = match;
  const [itemToEdit, setItemToEdit] = useState(null);
  const { data, loading: queryLoading } = useQuery(getKitchenQuery, {
    variables: { input: { kitchenId: params.kitchenId, includeMaxedOut: true } }
  });

  const [editItem, { loading, error: mutationError }] = useMutation(
    editItemMutation,
    {
      onCompleted: () => {
        props.history.push(`/kitchen/${params.kitchenId}/menu`);
      }
    }
  );

  useEffect(() => {
    if (data) {
      const { getKitchen } = data;
      const kitchen = getKitchen[0];
      const { menuItems } = kitchen;
      const selectedItem = menuItems.find((item) => item.id === params.itemId);
      setItemToEdit(selectedItem);
    }
  }, [data, params.itemId]);

  const handleSave = (values) => {
    // The only options for number of sides right now are 1 or none
    const numberOfSides = values.includeSide ? 1 : 0;
    // only specials allow max orders to be set
    const maxOrders = values.type === 'SPECIAL' ? values.maxOrders : 0;

    delete itemToEdit.__typename;
    editItem({
      variables: {
        input: {
          kitchenId: params.kitchenId,
          menuItem: {
            ...itemToEdit,
            name: values.name,
            price: values.price,
            itemType: values.type,
            numberOfSides,
            menuType: values.menu,
            options: values.options,
            maxOrders
          }
        }
      }
    });
  };
  if (queryLoading) {
    return <CircularProgress className={classes.progress} size='2em' />;
  }
  if (data && itemToEdit) {
    const inititialSideValue = itemToEdit.numberOfSides === 1 ? true : false;
    const options = itemToEdit.options ? itemToEdit.options : [];
    const initialValues = {
      name: itemToEdit.name,
      price: itemToEdit.price,
      type: itemToEdit.itemType,
      menu: itemToEdit.menuType,
      includeSide: inititialSideValue,
      options,
      maxOrders: itemToEdit.maxOrders
    };
    return (
      <Container>
        <Paper elevation={0} className={classes.addItem}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/kitchens'>kitchens</Link>
            <Link to={`/kitchen/${params.kitchenId}/orders`}>
              {params.kitchenId} Orders
            </Link>
            <Link to={`/kitchen/${params.kitchenId}/menu`}>Manage Menu</Link>
            <Typography color='textPrimary'>Edit Menu Item</Typography>
          </Breadcrumbs>
        </Paper>
        <Box display='flex' flexDirection='column' mb={3} mt={3}>
          <Typography variant='h6' component='h1'>
            {params.kitchenId} - Edit Menu Item
          </Typography>
          {mutationError && (
            <Typography color='error' gutterBottom={true}>
              There was an error. Submission could not be processed
            </Typography>
          )}
          {loading ? (
            <CircularProgress className={classes.progress} size='2em' />
          ) : null}
          <ItemForm
            handleSave={handleSave}
            initialFormValues={initialValues}
            disabled={loading}
            {...props}
          />
        </Box>
      </Container>
    );
  }
  return null;
};

export default EditMenuItem;
