import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isWeekend } from 'date-fns';

import isMenuAvailable from '../../../utils/is-menu-available';
import getPickupDate from '../../../utils/get-pickup-date';
import isBlackoutDate from '../../../utils/blackout-dates';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '250px',
    marginBottom: '20px'
  }
}));

const MenuLandingPage = (props) => {
  const classes = useStyles();
  const { match } = props;
  const { path } = match;
  const isClosedForWeekend =
    isWeekend(getPickupDate()) && path !== '/bypass-orders-closed';
  const isBlackedOut = 
    isBlackoutDate(getPickupDate()) && path !== '/bypass-orders-closed';
  const isBreakfastAvailable = isMenuAvailable('BREAKFAST');
  const isLunchAvailable = isMenuAvailable('LUNCH');
  const isSampleSaleAvailable = isMenuAvailable('SAMPLESALE');
  const breakfastPath =
    path === '/bypass-orders-closed'
      ? '/bypass-orders-closed/breakfast'
      : '/breakfast-menu';
  const lunchPath =
    path === '/bypass-orders-closed'
      ? '/bypass-orders-closed/lunch'
      : '/lunch-menu';
  const sampleSalePath =
    path === '/bypass-orders-closed'
      ? '/bypass-orders-closed/sample-sale'
      : '/sample-sale';
  if (
    !isSampleSaleAvailable && (
      isClosedForWeekend ||
      isBlackedOut ||
      (!isBreakfastAvailable &&
        !isLunchAvailable &&
        !isSampleSaleAvailable &&
        path !== '/bypass-orders-closed')
      )  
  ) {
    return (
      <Box mt={3}>
        <Typography
          variant='h3'
          color='error'
          className={classes.ordersClosedTitle}
          align='center'
        >
          {isClosedForWeekend
            ? 'Sorry, ordering is currently closed. It will reopen on Sunday at noon'
            : 'Sorry, ordering is currently closed.'}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='100%'
      mt={3}
    >
      {isLunchAvailable || path === '/bypass-orders-closed' ? (
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={() => props.history.push(lunchPath)}
          className={classes.button}
        >
          Order Lunch
        </Button>
      ) : null}
      {isBreakfastAvailable || path === '/bypass-orders-closed' ? (
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={() => props.history.push(breakfastPath)}
          className={classes.button}
        >
          Order Breakfast
        </Button>
      ) : null}
      {isSampleSaleAvailable || path === '/bypass-orders-closed/sample-sale' ? (
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={() => props.history.push(sampleSalePath)}
          className={classes.button}
        >
          Sample Sale
        </Button>
      ) : null}
    </Box>
  );
};

export default MenuLandingPage;
