import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  makeStyles,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const {
  REACT_APP_GRAPHQL_ENDPOINT: endpoint,
  REACT_APP_API_KEY,
  REACT_APP_USER_POOL_CLIENT: clientId
} = process.env;
const challengePath = '/challenge';
const challengeUrl = `${endpoint}${challengePath}`;
const useStyles = makeStyles((theme) => ({
  loginButton: {
    marginTop: '1em'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));
const headers = {
  'x-api-key': REACT_APP_API_KEY
};

const AuthChallenge = (props) => {
  const classes = useStyles();
  const { user } = props;
  const [password, setPassword] = useState({ first: '', second: '' });
  const [isDisabled, setIsDisabled] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarError] = useState('Problem with Request');
  const [isLoading, setIsLoading] = useState(false);

  const isValidPassword = useCallback((string) => {
    const lowercase = /.*[a-z].*/;
    const uppercase = /.*[A-Z].*/;
    const numeric = /.*[0-9].*/;
    // eslint-disable-next-line no-useless-escape
    const special = /.*[*.!@#\$%\^\&*\\].*/;
    const passed = [];

    if (string.length >= 8) {
      if (lowercase.test(string)) {
        passed.push('lowercase');
      }

      if (uppercase.test(string)) {
        passed.push('uppercase');
      }

      if (numeric.test(string)) {
        passed.push('numeric');
      }

      if (special.test(string)) {
        passed.push('special');
      }

      return passed.length === 4;
    }

    return false;
  }, []);

  useEffect(() => {
    const validPassword = isValidPassword(password.first);

    if (validPassword && password.first === password.second) {
      return setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [password, isValidPassword, setIsDisabled]);

  if (!props || (props && (!props.Session || !props.ChallengeName))) {
    return props.history.push('/kitchen');
  }

  const handleAuthChallenge = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    const payload = {
      clientId,
      email: user,
      pwd: password.second,
      challengeName: props.ChallengeName,
      session: props.Session
    };

    try {
      const result = await axios.post(challengeUrl, payload, { headers });
      const { data } = result;

      return props.success(data);
    } catch (err) {
      Sentry.captureException(err);
      setShowSnackbar(true);
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    switch (name) {
      case 'first':
        setPassword({ first: value, second: '' });
        break;
      case 'second':
        setPassword({ first: password.first, second: value });
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth='xs'>
      <Box
        marginTop={2}
        display='flex'
        flexDirection='column'
        textAlign='center'
      >
        {isLoading ? (
          <CircularProgress className={classes.progress} size='2em' />
        ) : null}
        <Typography variant='h3' gutterBottom={true}>
          Confirm your email
        </Typography>
        <Typography gutterBottom={true}>{user}</Typography>
        <TextField
          label='Password'
          name='first'
          type='password'
          variant='outlined'
          margin='dense'
          value={password.first}
          onChange={handleChange}
        />
        <TextField
          label='Confirm Password'
          name='second'
          type='password'
          variant='outlined'
          margin='dense'
          value={password.second}
          onChange={handleChange}
        />

        <Typography variant='caption'>
          Must be 8 characters in length and
          <br />
          contain one of each item below:
          <br />
          <ul>
            <li>uppercase letters</li>
            <li>lowercase letters</li>
            <li>numbers</li>
            <li>special characters: !@#$%^&*</li>
          </ul>
        </Typography>
        <Button
          disabled={isDisabled}
          onClick={handleAuthChallenge}
          color='primary'
          variant='contained'
          className={classes.loginButton}
        >
          Change Password
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert variant='filled' onClose={handleCloseSnackbar} severity='error'>
          {snackbarError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AuthChallenge;
