import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import AuthContext from '../../../context/auth-context.js';
import Login from '../login.js';
import AuthChallenge from '../auth-challenge.js';

const AuthenticatedRoute = (args) => {
  const [state] = useContext(AuthContext);

  if (state && state.isAuthenticated === false && state.ChallengeName) {
    return <AuthChallenge {...state} {...args} />;
  }

  if (!state || (state && state.isAuthenticated === false)) {
    return <Login {...args} />;
  }

  const { component: Component, ...rest } = args;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default AuthenticatedRoute;
