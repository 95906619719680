import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Button
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

import getOrders from './queries/get-orders.js';
import markOrderReadyForPickupMutation from './mutations/mark-order-ready-for-pickup.js';
import formatTime from '../../utils/format-time';
import { sampleSale } from '../../constants';

import AuthContext from '../../context/auth-context.js';
import OrderTable from 'mui-datatables';
import orderTableColumns from './components/order-table-columns';
import FilterSelect from './components/filter-select';
import FilterDate from './components/filter-date';
import SampleSaleAuth from './components/sample-sale-auth';

const exportDate = format(new Date(), 'yyyy-MM-dd');
const orderTableOptions = {
  selectableRows: 'none',
  elevation: 0,
  print: false,
  viewColumns: false,
  search: false,
  download: true,
  downloadOptions: {
    filename: `${exportDate}-orders.csv`
  },
  filter: false,
  responsive: 'standard'
};

const useStyles = makeStyles((theme) => ({
  orders: {
    width: '100%',
    marginTop: '2em',
    '& .progress': {
      position: 'absolute',
      top: '30%',
      left: '50%',
      zIndex: 1
    },
    '& .breadcrumbs': {
      marginBottom: '2em'
    },
    '& .ready-filter': {
      display: 'flex',
      height: 45,
      marginBottom: '2em',
      '& div:nth-child(2)': {
        borderRight: '0px'
      },
      '& .title': {
        height: 45,
        marginRight: '1em',
        display: 'flex',
        alignItems: 'center'
      },
      '& .item': {
        height: 45,
        width: 200,
        border: '1px solid #000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      },
      '& .selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    },
    '& .ready-check': {
      color: theme.palette.success.main
    },
    '& .not-ready-check': {
      color: theme.palette.disable.main
    },
    '& .not-ready-check:hover': {
      color: theme.palette.success.main
    }
  }
}));

const Orders = (props) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(AuthContext);
  const [readyFilter, setReadyFilter] = useState(false);
  const [itemFilter, setItemFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [
    markOrderReadyForPickup,
    { called, loading: mutationLoading }
  ] = useMutation(markOrderReadyForPickupMutation);
  const { match } = props;
  const { params } = match;

  const makeSelected = readyFilter === false ? 'selected' : '';
  const readySelected = readyFilter === true ? 'selected' : '';
  const itemAttributeValue = itemFilter ? { itemId: itemFilter } : {};
  const timeAttributeValue = timeFilter ? { pickupTime: timeFilter } : {};
  const queryOrderDate = dateFilter ? { orderDate: dateFilter } : {};

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    getOrders,
    {
      variables: {
        input: {
          password: state.superSecret,
          kitchenId: params.kitchenId,
          ready: readyFilter,
          ...itemAttributeValue,
          ...timeAttributeValue,
          ...queryOrderDate
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (called === true && mutationLoading === true) {
      return startPolling(200);
    }

    stopPolling();
  }, [called, startPolling, stopPolling, mutationLoading]);

  if (error) {
    return <Box>{error.message}</Box>;
  }

  if (loading || mutationLoading || !data) {
    return (
      <Box className={classes.orders}>
        <CircularProgress className='progress' size='2em' />
      </Box>
    );
  }

  const handleDateChange = (event) => {
    const { target } = event;
    const { value } = target;
    setDateFilter(value);
  };

  const { getOrders: orders } = data;
  const _orders = orders.map((order) => {
    const {
      id,
      orderNumber,
      firstName,
      lastName,
      pickupTime,
      items,
      ready
    } = order;
    const name = `${lastName}, ${firstName}`;
    const _pickupTime = formatTime(pickupTime);
    const _item = items[0];
    const { sides, options } = _item;
    const itemName =
      options && Array.isArray(options) && options[0]
        ? `${_item.name} - ${options[0]}`
        : _item.name;
    const side = sides && Array.isArray(sides) && sides[0] ? sides[0].name : '';
    return [id, orderNumber, name, _pickupTime, itemName, side, ready];
  });

  const handleReadyFilter = (isReady) => {
    setReadyFilter(isReady);
  };

  const handleReadyClick = (orderId) => {
    const variables = { id: orderId };
    markOrderReadyForPickup({ variables });
  };

  const handleItemFilter = (event) => {
    const { target } = event;
    const { value } = target;
    setItemFilter(value);
  };

  const handleTimeFilter = (event) => {
    const { target } = event;
    const { value } = target;
    setTimeFilter(value);
  };

  const renderReady = (value, tableMeta) => {
    const { rowData } = tableMeta;
    const orderId = rowData[0];
    const ready = rowData[rowData.length - 1];

    if (ready === true) {
      return <CheckCircleIcon className='ready-check' />;
    }

    return (
      <CheckCircleIcon
        className='not-ready-check'
        onClick={() => handleReadyClick(orderId)}
      />
    );
  };

  const readyColumnIndex = orderTableColumns.findIndex(
    (item) => item.name === 'ready'
  );
  orderTableColumns[readyColumnIndex].options.customBodyRender = renderReady;
  if (
    params.kitchenId === sampleSale.kitchenId &&
    state &&
    !state.isSampleSaleAuthenticated
  ) {
    return <SampleSaleAuth />;
  }
  return (
    <Container className={classes.orders}>
      <Paper elevation={0} className='breadcrumbs'>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/kitchens'>kitchens</Link>
          <Typography color='textPrimary'>{params.kitchenId} Orders</Typography>
        </Breadcrumbs>
      </Paper>
      <Box className='ready-filter' justifyContent='space-between'>
        <Box display='flex'>
          <Typography component='div' className='title'>
            Order Status:
          </Typography>
          <Box
            className={`item ${makeSelected}`}
            onClick={() => handleReadyFilter(false)}
          >
            <Typography component='span'>Ready to Make</Typography>
          </Box>
          <Box
            className={`item ${readySelected}`}
            onClick={() => handleReadyFilter(true)}
          >
            <Typography component='span'>Ready for Pickup</Typography>
          </Box>
        </Box>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            props.history.push(`/kitchen/${params.kitchenId}/menu`);
          }}
        >
          Manage Menu
        </Button>
      </Box>
      <Container>
        <FilterSelect
          selectedTime={timeFilter}
          selectedItem={itemFilter}
          onChange={handleTimeFilter}
          kitchenId={params.kitchenId}
          handleSelectTime={handleTimeFilter}
          handleSelectItem={handleItemFilter}
        />
        <FilterDate
          kitchenId={params.kitchenId}
          value={dateFilter}
          handleChange={handleDateChange}
        />
      </Container>
      <OrderTable
        title={`${params.kitchenId} Orders`}
        data={_orders}
        columns={orderTableColumns}
        options={orderTableOptions}
      />
    </Container>
  );
};

export default Orders;
