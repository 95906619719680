import React, { useState } from 'react';
import { format } from 'date-fns';
import { Formik } from 'formik';
import {
  Button,
  TextField,
  Paper,
  Typography,
  FormHelperText,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

import validationSchema from '../menu/validation-schema';
import isMenuAvailable from '../../../utils/is-menu-available';

import CodeOfConduct from './code-of-conduct';
import TimeSelect from '../menu/time-select';
import { desiredDateFormats, sampleSale } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    width: '100%'
  },
  submitButton: {
    marginTop: '2em'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  },
  ordersClosedTitle: {
    marginBottom: '1.5em'
  },
  smsDisclaimer: {
    marginTop: '1em'
  },
  divider: {
    height: '1px',
    marginTop: '1em'
  },
  itemSection: {
    margin: '1.5em 0em 0em 0em',
    padding: '1em 0',
    borderTop: 'solid 1px black',
    borderBottom: 'solid 1px black'
  },
  codeSection: {
    padding: '1em 0',
    margin: '0em 0em 2em 0em'
  }
}));

const SampleSaleScreen = (props) => {
  const classes = useStyles();
  const { saveOrderDetails } = props;
  const { dynamoFormat, displayFormat } = desiredDateFormats;
  const kitchenId = 'MORSE-ROAD';
  const { match } = props;
  const { path } = match;
  const available = isMenuAvailable('SAMPLESALE');
  const [codeOfConductChecked, setCodeOfConductChecked] = useState(false);
  const [itemChecked, setItemChecked] = useState(false);
  const renderDateOptions = () => {
    const { pickupDates } = sampleSale;
    return pickupDates.map((pickupDate) => {
      return (
        <MenuItem key={pickupDate} value={pickupDate}>
          {format(pickupDate, displayFormat)}
        </MenuItem>
      );
    });
  };

  const initialFormValues = {
    firstName: '',
    lastName: '',
    orderDate: '',
    pickupTime: '',
    phone: '',
    email: '',
    associateId: ''
  };

  const handleCheckItem = (event) => {
    const { target } = event;
    const { checked } = target;
    setItemChecked(checked);
  };

  const handleCodeOfConduct = (event) => {
    const { target } = event;
    const { checked } = target;
    setCodeOfConductChecked(checked);
  };

  const proceedToPayment = (values) => {
    const _orderDate = format(values.orderDate, dynamoFormat);
    // TODO add item to Dynamo - replace constant with data from query
    const { orderItemInput } = sampleSale;
    const orderObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      pickupTime: values.pickupTime,
      phone: values.phone,
      kitchenId,
      orderDate: _orderDate,
      associateId: values.associateId,
      email: values.email,
      codeOfConduct: true,
      items: [orderItemInput]
    };
    saveOrderDetails(orderObject);
    props.history.push('/payment');
  };

  if (!available && path !== '/bypass-orders-closed/sample-sale') {
    return (
      <Box marginTop={3} textAlign='center'>
        <Typography
          variant='h3'
          color='error'
          className={classes.ordersClosedTitle}
        >
          Sorry, Sample Sale registration is currently closed.
        </Typography>
      </Box>
    );
  }

  return (
    <Paper elevation={0}>
      <Typography color='primary' variant='body2'>
        Please fill out your order details below.
      </Typography>
      <Formik
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormValues}
        onSubmit={proceedToPayment}
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors
          } = props;

          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                required
                label='First Name'
                onChange={handleChange}
                value={values.firstName}
                name='firstName'
                type='text'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <TextField
                required
                label='Last Name'
                onChange={handleChange}
                value={values.lastName}
                name='lastName'
                type='text'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <TextField
                required
                label='Associate ID'
                onChange={handleChange}
                value={values.associateId}
                name='associateId'
                type='text'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <Typography
                color='primary'
                variant='body2'
                className={classes.smsDisclaimer}
              >
                You will receive a text message with your order confirmation and
                receipt.
              </Typography>

              <NumberFormat
                onValueChange={(val) => setFieldValue('phone', val.value)}
                value={values.phone}
                name='phone'
                type='tel'
                mask='_'
                margin='dense'
                label='Cell Phone Number'
                required
                variant='outlined'
                allowEmptyFormatting
                format='+1 (###) ###-####'
                customInput={TextField}
              />
              {errors && errors.phone ? (
                <FormHelperText error={true}>{errors.phone}</FormHelperText>
              ) : null}
              {/* TODO add validation and/or mask for email field */}
              <TextField
                required
                label='Email'
                onChange={handleChange}
                value={values.email}
                name='email'
                type='email'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <FormControl className={classes.select}>
                <InputLabel id='date-select'>Select a Pickup Date</InputLabel>
                <Select
                  labelId='date-select'
                  name='orderDate'
                  value={values.orderDate}
                  onChange={handleChange}
                >
                  {renderDateOptions()}
                </Select>
              </FormControl>
              {values.orderDate ? (
                <TimeSelect
                  handleChange={handleChange}
                  values={values}
                  selectedKitchen={kitchenId}
                  pickupDate={values.orderDate}
                  menuType='SAMPLESALE'
                  showPickupDate={false}
                />
              ) : null}
              <Box className={classes.itemSection}>
                <Typography variant='subtitle2'>
                  Please select your order:
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemChecked}
                      onChange={handleCheckItem}
                      name='item'
                      color='primary'
                    />
                  }
                  label='L Brands Sample Sale - $75'
                />
              </Box>
              <Box className={classes.codeSection}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={codeOfConductChecked}
                      onChange={handleCodeOfConduct}
                      name='codeOfConduct'
                      color='primary'
                    />
                  }
                  label={<CodeOfConduct />}
                />
              </Box>
              {values.orderDate &&
              values.pickupTime &&
              itemChecked &&
              codeOfConductChecked ? (
                <Button
                  className={classes.submitButton}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Proceed to Payment
                </Button>
              ) : null}
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default SampleSaleScreen;
