import gql from 'graphql-tag';

export default gql`
  mutation eightySixMenuItemMutation($input: EightySixItemInput!) {
    eightySixMenuItem(input: $input) {
      id 
      name
      menuItems {
        id 
        name
        price
        itemType
        eightySixed
      }
    }
  }
`;
