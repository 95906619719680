import React, { useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import validatePasswordQuery from '../queries/validate-password';

import AuthContext from '../../../context/auth-context';
import { sampleSale } from '../../../constants';
const { kitchenId } = sampleSale;

const useStyles = makeStyles((theme) => ({
  loginButton: {
    marginTop: '.5em'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));

const SampleSaleAuthForm = (props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(AuthContext);
  const [validatePassword, { data, loading }] = useLazyQuery(
    validatePasswordQuery,
    {
      onCompleted: () => handleSubmitPassword(data)
    }
  );
  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    setInputValue(value);
  };

  const handleSubmitPassword = (data) => {
    if (data && data.validatePassword && data.validatePassword === true) {
      dispatch({ type: 'sample-sale-success', payload: inputValue });
    } else {
      setInputValue('');
      setShowSnackbar(true);
      return null;
    }
  };

  return (
    <Container maxWidth='xs'>
      <Box
        marginTop={2}
        display='flex'
        flexDirection='column'
        textAlign='center'
      >
        <Typography variant='h3' gutterBottom={true}>
          Sample Sale Login
        </Typography>
        <TextField
          name='password'
          value={inputValue}
          onChange={handleChange}
          variant='outlined'
          margin='dense'
          label='Password'
          type='password'
        />
        <Button
          color='primary'
          variant='contained'
          onClick={() =>
            validatePassword({
              variables: {
                input: { kitchenId, password: inputValue }
              }
            })
          }
          className={classes.loginButton}
          disabled={loading}
        >
          {loading ? 'processing' : 'Login'}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          variant='filled'
          onClose={() => setShowSnackbar(false)}
          severity='error'
        >
          There was a problem with your login
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SampleSaleAuthForm;
