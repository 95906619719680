import React, { Fragment } from 'react';
import { Link, Typography } from '@material-ui/core';

const text =
  'I acknowledge that I have received, and understand it is my responsibility to comply with the sample sale guidelines as stated in the Code of Conduct';
const path = 'https://www.lb.com/associates/our-code-of-conduct/translations';

const CodeOfConduct = () => {
  return (
    <Fragment>
      <Typography>
        {text}{' '}
        <Link href={path} color='primary' target='_blank'>
          {path}
        </Link>
      </Typography>
    </Fragment>
  );
};
export default CodeOfConduct;
