import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Box,
  Typography
} from '@material-ui/core';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;

    if (hasError === true) {
      return (
        <Box>
          <Typography>OoPs!</Typography>
        </Box>
      );
    }

    return this.props.children;
  }
  
}

export default ErrorHandler;
