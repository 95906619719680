/*
 * This function will remove __typename
 * attributes at the various levels of the object.
 *
 * @param {object}
 */
import traverse from 'traverse';
import { has } from 'lodash';

const cleanTypeName = data => {
  traverse(data).forEach(function(item) {
    if (has(item, '__typename')) {
      delete item.__typename;
    }
  });
  return data;
};
export default cleanTypeName;
