/*
 * determines whether an order's pickup date will be today or tomorrow
 * and returns it as a date instance
 * currently orders placed between 12:00PM and 11:59PM are picked up the following day
 */

const getPickupDate = () => {
  const dateInstance = new Date();
  const hours = dateInstance.getHours();
  if (hours >= 12 && hours <= 23) {
    const tomorrow = dateInstance.getDate() + 1;
    dateInstance.setDate(tomorrow);
  }
  return dateInstance;
};

export default getPickupDate;
