import gql from 'graphql-tag';

export default gql`
  query getAvailablePickupTimesQuery($input: FilterInput!) {
    getAvailablePickupTimes(input: $input) {
      time
      maxOrders
    }
  }
`;
