import gql from 'graphql-tag';

export default gql`
  query getKitchenQuery($input: FilterInput!) {
    getKitchen(input: $input) {
      name
      id
      serviceTimes {
        time
      }
      menuItems {
        name
        id
        itemType
        menuType
        price
        numberOfSides
        eightySixed
        options
        maxOrders
      }
    }
  }
`;
