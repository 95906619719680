import { isSameDay } from 'date-fns';
const _ = require('lodash');

const blackoutDates = [
    new Date(2020, 11, 24),
    new Date(2020, 11, 25),
    new Date(2020, 11, 31),
    new Date(2020, 12, 1),
    new Date(2020, 12, 18),
    new Date(2021, 1, 15),
    new Date(2021, 4, 31),
    new Date(2021, 5, 18),
    new Date(2021, 5, 19),
    new Date(2021, 6, 4),
    new Date(2021, 6, 5),
    new Date(2021, 8, 6),
    new Date(2021, 10, 25),
    new Date(2021, 11, 24),
    new Date(2021, 11, 25),
    new Date(2021, 11, 31),
    new Date(2021, 12, 1)
];

const isBlackoutDate = (pickupDate) => {
    // Return true if pickupDate is in list of blackoutDates
    return !_.isEmpty(_.filter(blackoutDates, blackoutDate => isSameDay(blackoutDate, pickupDate)));
};

export default isBlackoutDate;