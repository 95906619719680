import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';

const OrderErrorScreen = (props) => {
  const { history } = props;
  return (
    <Box marginTop={3} textAlign='center'>
      <Typography
        variant='h3'
        color='error'
        gutterBottom={true}
        // className={classes.ordersClosedTitle}
      >
        Sorry, there was a problem creating your order.
      </Typography>
      <Typography gutterBottom={true}>Please try again.</Typography>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          history.push('/');
        }}
      >
        Restart
      </Button>
    </Box>
  );
};

export default OrderErrorScreen;
