import React from 'react';
import { format } from 'date-fns';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import { desiredDateFormats, sampleSale } from '../../../constants';

const { displayFormat, dynamoFormat } = desiredDateFormats;
const FilterDate = (props) => {
  if (!props || props.kitchenId !== sampleSale.kitchenId) return null;

  const { value, handleChange } = props;

  const renderDateOptions = () => {
    const { pickupDates } = sampleSale;
    return pickupDates.map((pickupDate) => {
      return (
        <MenuItem key={pickupDate} value={format(pickupDate, dynamoFormat)}>
          {format(pickupDate, displayFormat)}
        </MenuItem>
      );
    });
  };

  return (
    <Box
      display='flex'
      flexWrap='wrap'
      width='100%'
      alignItems='center'
      justifyContent='space-between'
      maxWidth='500px'
    >
      <Box display='flex' alignItems='center' mr='1.5em'>
        <Typography id={`time-filter-select-label}`} variant='body2'>
          Filter by Pickup Date
        </Typography>
      </Box>
      <Box maxWidth='300px' flex='1 0 25%'>
        <FormControl variant='outlined' fullWidth={true} margin='dense'>
          <Select value={value} onChange={handleChange} displayEmpty>
            <MenuItem value=''>None</MenuItem>
            {renderDateOptions()}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default FilterDate;
