import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import {
  Container,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import createItemMutation from '../mutations/create-menu-item';

import ItemForm from './form';

const useStyles = makeStyles((theme) => ({
  addItem: {
    width: '100%',
    marginTop: '2em',
    '& .breadcrumbs': {
      marginBottom: '2em'
    }
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));

const CreateMenuItem = (props) => {
  const classes = useStyles();
  const { match } = props;
  const { params } = match;
  const [createItem, { loading, error }] = useMutation(createItemMutation, {
    onCompleted: () => {
      props.history.push(`/kitchen/${params.kitchenId}/menu`);
    }
  });

  const handleSave = (values) => {
    // The only options for number of sides right now are 1 or none
    const numberOfSides = values.includeSide ? 1 : 0;
    // only specials allow max orders to be set
    const maxOrders = values.type === 'SPECIAL' ? values.maxOrders : 0;
    createItem({
      variables: {
        input: {
          kitchenId: params.kitchenId,
          menuItem: {
            name: values.name,
            price: values.price,
            itemType: values.type,
            numberOfSides,
            menuType: values.menuType,
            options: values.options,
            maxOrders
          }
        }
      }
    });
  };

  const initialValues = {
    name: '',
    price: 0,
    type: 'NONE',
    menu: 'LUNCH',
    includeSide: false,
    options: [],
    maxOrders: 0
  };

  return (
    <Container>
      <Paper elevation={0} className={classes.addItem}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/kitchens'>kitchens</Link>
          <Link to={`/kitchen/${params.kitchenId}/orders`}>
            {params.kitchenId} Orders
          </Link>
          <Link to={`/kitchen/${params.kitchenId}/menu`}>Manage Menu</Link>
          <Typography color='textPrimary'>Add Menu Item</Typography>
        </Breadcrumbs>
      </Paper>
      <Box display='flex' flexDirection='column' mb={3} mt={3}>
        <Typography variant='h6' component='h1'>
          {params.kitchenId} - Add Menu Item
        </Typography>
        {error && (
          <Typography color='error' gutterBottom={true}>
            There was an error. Submission could not be processed
          </Typography>
        )}
        {loading ? (
          <CircularProgress className={classes.progress} size='2em' />
        ) : null}
        <ItemForm
          handleSave={handleSave}
          initialFormValues={initialValues}
          disabled={loading}
          {...props}
        />
      </Box>
    </Container>
  );
};

export default CreateMenuItem;
